.root {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.fullFrame {
  height: calc(100% - var(--top-bar-menu-height));
}

.nav {
  flex-shrink: 0;
  background-color: var(--ambient-base);
}

.content {
  overflow-y: scroll;
  flex-grow: 1;
  overflow: hidden;
}
