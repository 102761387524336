html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333e48;
  font-weight: 700;
}

h1 {
  font-size: 32px;
  line-height: 34px;
}

h2 {
  font-size: 28px;
  line-height: 30px;
}

h3 {
  font-size: 24px;
  line-height: 26px;
}

h4 {
  font-size: 20px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
  line-height: 18px;
}

h6 {
  font-size: 14px;
  line-height: 16px;
}

p {
  margin-top: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

table {
  border-collapse: collapse;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
}

.leaflet-popup-content {
  margin: 0px;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: var(--accent-base);
  font-weight: bold;
}

:root {
  --donut-margin: 20%;
}
